
#snow>div {
  position: fixed;
  z-index: 100;
  pointer-events: none;
  top: 0;
}

#snow .snow {
  background: url(/assets/images/normal-snow.png);
  background-position: center;
  background-size: cover;
  pointer-events: auto;
  cursor: pointer;
}

#snow .special-snow {
  background: url(/assets/images/special-snow.png);
  background-position: center;
  background-size: cover;
}

#snow .crying-snow {
  background: url(/assets/images/crying-santa.gif);
  background-position: top;
  background-size: contain;
}

#santa-title span {
  display: inline-block;
  animation: wave 0.8s infinite ease-in-out;
}
#santa-title span:nth-child(1) {
  animation-delay: 0s;
}
#santa-title span:nth-child(2) {
  animation-delay: 0.2s;
}
#santa-title span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
